export default [
    {
      path: '/promociones',
      name: 'promociones',
      component: () => import('@/views/admin/Promociones.vue'),
      //component: () => import('@/views/licenses/users-list/UsersList.vue'),
    },

    /* PARTNERS */
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@/views/partners/List.vue'),
      meta: {
        resource: 'Partners',
        action: 'read',          
      },    
    },
    {
      path: '/partners/view/:id',
      name: 'partners-view',
      component: () => import('@/views/partners/View.vue'),
      meta: {
        resource: 'Partners',
        action: 'read',
      }
    },
    {
      path: '/partners/edit/:id',
      name: 'partners-edit',
      component: () => import('@/views/partners/Edit.vue'),
      meta: {
        resource: 'Partners',
        action: 'update',
      },
    },


    {
        path: '/licences',
        name: 'licences',
        component: () => import('@/views/licenses/List.vue'),
        meta: {
          resource: 'Licences',
          action: 'create',
        },
    },
    {
      path: '/licences/view/:id',
      name: 'licences-view',
      component: () => import('@/views/licenses/View.vue'),
      meta: {
        resource: 'Licences',
        action: 'read',
      },
    },
    {
      path: '/licences/edit/:id',
      name: 'licences-edit',
      component: () => import('@/views/licenses/Edit.vue'),
      meta: {
        resource: 'Licences',
        action: 'update',
      },
    },
    {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/admin/RolesAndPermissions.vue'),
        meta: {
          resource: 'Roles',
          action: 'read',
        },
    },
    {
        path: '/hierarchy',
        name: 'hierarchy',
        component: () => import('@/views/admin/Hierarchy.vue'),
        meta: {
          resource: 'Hierarchy',
          action: 'read',
        },
    },

    // SCHOOLS
    {
        path: '/schools',
        name: 'schools',
        component: () => import('@/views/schools/List.vue'),
        meta: {
          resource: 'Schools',
          action: 'create',
        },
    },
    {
      path: '/schools/view/:id',
      name: 'school-view',
      component: () => import('@/views/schools/View.vue'),
      meta: {
        resource: 'Schools',
        action: 'read',

      },
    },
    {
      path: '/schools/edit/:id',
      name: 'school-edit',
      component: () => import('@/views/schools/Edit.vue'),
      meta: {
        resource: 'Schools',
        action: 'update',
      },
    },
    {
      path: '/schools/upload/:id',
      name: 'school-upload',
      component: () => import('@/views/schools/Upload.vue'),
      meta: {
        resource: 'School Structure',
        action: 'read',
      },
    },
    /* TEACHERS */
    {
      path: '/schools/view/:id/teachers',
      name: 'school-teachers-list',
      component: () => import('@/views/schools/teachers/List.vue'),
      meta: {
        resource: 'SchoolLicences',
        action: 'read',
        //middleware
      },
    },
    {
      path: '/schools/view/:id/teachers/view/:teacher_id/',
      name: 'school-teachers-view',
      component: () => import('@/views/schools/teachers/View.vue'),
      meta: {
        resource: 'SchoolLicences',
        action: 'read',
      },
    },
    {
      path: '/schools/view/:id/teachers/edit/:teacher_id/',
      name: 'school-teachers-edit',
      component: () => import('@/views/schools/teachers/Edit.vue'),
      meta: {
        resource: 'SchoolLicences',
        action: 'update',
      },
    },

    /* STUDENTS */
    {
      path: '/schools/view/:id/students',
      name: 'school-students-list',
      component: () => import('@/views/schools/students/List.vue'),
      meta: {
        resource: 'SchoolLicences',
        action: 'read',
        //middleware
      },
    },
    {
      path: '/schools/view/:id/students/view/:student_id/',
      name: 'students-view',
      component: () => import('@/views/schools/students/View.vue'),
      meta: {
        resource: 'SchoolLicences',
        action: 'read',
      },
    },
    {
      path: '/schools/view/:id/students/edit/:student_id/',
      name: 'students-edit',
      component: () => import('@/views/schools/students/Edit.vue'),
      meta: {
        resource: 'Licences',
        action: 'update',
      },
    },

    /* GROUPS */
    {
      path: '/schools/view/:id/groups',
      name: 'school-groups-list',
      component: () => import('@/views/schools/groups/List.vue'),
      meta: {
        resource: 'SchoolGroups',
        action: 'read',
        //middleware
      },
    },
    {
      path: '/schools/view/:id/groups/view/no-group/',
      name: 'school-groups-view-no-group',
      component: () => import('@/views/schools/groups/NoGroupView.vue'),
      meta: {
        resource: 'SchoolGroups',
        action: 'read',
      },
    },
    {
      path: '/schools/view/:id/groups/view/:group_id/',
      name: 'school-groups-view',
      component: () => import('@/views/schools/groups/View.vue'),
      meta: {
        resource: 'SchoolGroups',
        action: 'read',
      },
    },
    {
      path: '/schools/view/:id/groups/edit/:group_id/',
      name: 'school-groups-edit',
      component: () => import('@/views/schools/groups/Edit.vue'),
      meta: {
        resource: 'SchoolGroups',
        action: 'update',
      },
    },

    /* DREAMERS */
    {
      path: '/schools/view/:id/dreamers',
      name: 'school-dreamers-list',
      component: () => import('@/views/schools/dreamers/List.vue'),
      meta: {
        resource: 'SchoolDreamers',
        action: 'read',
        //middleware
      },
    },
    {
      path: '/schools/view/:id/dreamers/view/:dreamer_id/',
      name: 'school-dreamers-view',
      component: () => import('@/views/schools/dreamers/View.vue'),
      meta: {
        resource: 'SchoolDreamers',
        action: 'read',
      },
    },
    {
      path: '/schools/view/:id/dreamers/edit/:dreamer_id/',
      name: 'school-dreamers-edit',
      component: () => import('@/views/schools/dreamers/Edit.vue'),
      meta: {
        resource: 'SchoolDreamers',
        action: 'update',
      },
    },
]