export default {
  namespaced: true,
  state: {
    hierarchy: [],
    learning_paths: [],
    contents:{},
    filtered_contents:{},
    contentFilterOptions:[],
    contentsHierarchy:[],
    search:{
      query:'',
      hierarchy_id:null,
      source:'all'
    },
    route:{
      id:null,
      title:{},
      description:{},
      languages: [],
      mode:null,
      img:1,
      contents:[],
      categories:[]
    },
    allocation:{},
    allocation_have_changed: false,
    dreamers_by_group: [],
    dreamers: {}
  },
  getters: {
    getSmileHierarchy: state => {
      const { hierarchy } = state
      let h = hierarchy.filter(h=> h.source == 'smileandlearn')
      return h[0].subcategories
    },
    getHierarchy: state => {
      return state.hierarchy
    },
    getLearningPaths: state => {
      return state.learning_paths
    },
    getContents: state => {
      return state.contents
    },
    getFilteredContents: state => {
      return state.filtered_contents
    },
    getContentFilterOptions: state => {
      return state.contentFilterOptions
    },
    getContentsHierarchy: state => {
      return state.contentsHierarchy
    },
    getRoute: state => {
      return state.route
    },
    getAllocation: state => {
      return state.allocation
    },
    getDreamersByGroup: state => {
      return state.dreamers_by_group
    },
    getDreamers: state => {
      return state.dreamers
    },
    getAllocationHaveChanged: state => {
      return state.allocation_have_changed
    }
  },
  mutations: {
    increment(state) {
      state.count++
    },
    setHierarchy(state, data) {
      state.hierarchy = data
    },
    setLearningPaths(state, data) {
      state.learning_paths = data
    },
    setRecommendedLearningPaths(state, data){
      let recom_lps = state.learning_paths.map( lp => {
        if(data[lp.id]){
          lp['source'] = lp['source'].concat('recommended')
          lp['explanation'] = data[lp.id].explanation
          lp['suggested_dreamers'] = data[lp.id].suggested_dreamers
        }
        return lp
      })
      state.learning_paths = recom_lps
    },
    setContents(state, data) {
      state.contents = data
      state.filtered_contents = data
    },
    setFilteredContents(state, data) {
      if(data.length == 0){
        state.filtered_contents = state.contents
      } else { 
        let contents_found = {}
        data.map(id => {
          contents_found[id] = state.contents[id]
        })
        state.filtered_contents = contents_found
      } 
    },
    setContentFilterOptions(state, data) {
      state.contentFilterOptions = data
    },
    setLanguageContentFilterOptions(state, data){
      let learningLanguagesOption = state.contentFilterOptions.find(option => option.name === "language_learning");
      if (learningLanguagesOption) {
        learningLanguagesOption.options = learningLanguagesOption.options.map(lang => {
          if (lang.id === data){ 
            return {...lang, selected: true}
          }
          return {...lang, selected: false}
        })
      }
    },
    setContentsHierarchy(state, data) {
      state.contentsHierarchy = data
    },
    handleFilterSelection(state, filters){
      state.contentFilterOptions.map(f => {
        if(filters[0]==f.name){
          f.options.map(op => {
            if(filters[1] == op.id){
              op.selected = !op.selected
              //state.contentFilterOptions[f.name].selected = !state.contentFilterOptions[f.name][op].selected
            }
          })
        }
      })
    },
    setRoute(state, data){
      state.route = data
    },
    setRouteLanguages(state, data){
      state.route.languages = data
    },
    setRouteId(state, data){
      state.route.id = data
    },
    setRouteContents(state, data){
      state.route.contents = data
    },
    setRouteImg(state, data){
      state.route.img = data
    },
    setRouteTitle(state, data){
      let value = data[0]
      let iso_code = data[1]
      let title_route_state = {...state.route.title}
      title_route_state[iso_code] = value
      state.route.title = title_route_state
    },
    setRouteDescription(state, data){
      let value = data[0]
      let iso_code = data[1]
      let description_route_state = {...state.route.description}
      description_route_state[iso_code] = value
      state.route.description = description_route_state
    },
    setRouteMode(state, data){
      state.route.mode = data
    },
    setRouteCategories(state, data){
      state.route.categories = data
    },
    resetAllocationChanges(state){
      state.allocation_have_changed = false
    },
    setAllocation(state, data){
      state.allocation_have_changed = false
      state.allocation = data
    },
    setDreamersByGroup(state, data){
      state.dreamers_by_group = data
      let dreamers = {}
      data.map(group => {
        group.linked.filter(d => dreamers[d.id] ? null : dreamers[d.id] = d)
      })
      state.dreamers = dreamers

    },
    allocateDreamer(state, data){
      let dreamers_id= data[0]
      let lp_id = data[1]
      let context = data[2]
      //// console.log(dreamers_id, context)
      let my_alloc = {...state.allocation}
      //// console.log(my_alloc)
      if(!my_alloc[lp_id]){
        my_alloc[lp_id] = {}
      }
      if(context == 'none'){
        if(dreamers_id.length == 0){
          my_alloc[lp_id] = {}
        } else {
          dreamers_id.forEach(dreamer_id => {
            if(my_alloc[lp_id][dreamer_id]){
              //// console.log("existe")
              delete my_alloc[lp_id][dreamer_id];
            }
          });
        }
      } else {
        dreamers_id.forEach(dreamer_id => {
          if(my_alloc[lp_id][dreamer_id]){
            //// console.log("existe")
            if(context == 'one'){
              delete my_alloc[lp_id][dreamer_id];
            }
          } else {
            //// console.log("no existe")
            my_alloc[lp_id][dreamer_id] = {
              start_date: null,
              end_date: null,
              order: lp_id,
            }
          }
        });
      }
      // console.log(my_alloc)
      state.allocation = my_alloc
      state.allocation_have_changed = true
    },
    allocateDreamerDate(state, data){
      // console.log(data)
      let type = data[0]
      let val = data[1]
      let dreamer_id = data[2]
      let lp_id = data[3]
      let my_alloc = {...state.allocation}

      if(my_alloc[lp_id][dreamer_id]){
        my_alloc[lp_id][dreamer_id][type] = val
      }
      state.allocation = my_alloc
      state.allocation_have_changed = true
    },
    allocateDreamerDateAll(state, data){
      let my_alloc = {...state.allocation}
      let start = data[0]
      let end = data[1]
      let lp_id = data[2]
      if(my_alloc[lp_id]){
        for (const [dreamer_id, value] of Object.entries(my_alloc[lp_id])) {
          // console.log(`${dreamer_id}: ${value}`);
          my_alloc[lp_id][dreamer_id] = {
            order: lp_id,
            start_date: start,
            end_date: end,
          }
        }
      }
      state.allocation = my_alloc
      state.allocation_have_changed = true
    },
    allocateDreamerDeleteAllDates(state, data){
      let my_alloc = {...state.allocation}
      let lp_id = data
      if(my_alloc[lp_id]){
        for (const [dreamer_id, value] of Object.entries(my_alloc[lp_id])) {
          // console.log(`${dreamer_id}: ${value}`);
          my_alloc[lp_id][dreamer_id] = {
            order: lp_id,
            start_date: null,
            end_date: null,
          }
        }
      }
      state.allocation = my_alloc
      state.allocation_have_changed = true
    },
    unselectAllAllocation(state){
      let prev_alloc = {...state.allocation}

      for (var [k, v] of Object.entries(state.allocation)){
        //// console.log(k, "-", v)
          prev_alloc= {...prev_alloc, [k]:{}}
      }

      state.allocation = prev_alloc
      state.allocation_have_changed = true
    }
  },
  actions: {},
}